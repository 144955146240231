import React, {useState, useEffect, Fragment} from "react";
import PropTypes                              from "prop-types";

const axios = require("axios").default;

export const ElementActiveSection = props => {

    const [inactiveStatus, setActiveStatus] = useState(props.inactiveStatus);

    useEffect(() => {
        props.onSetActiveStatus(inactiveStatus);
    }, [inactiveStatus]);

    const onSetActiveStatus = (event) => {
        const target = event.target;
        const status = target.dataset.inactivestatus;
        const newStatus = (status === "active") ? "inactive" : null ;
        setActiveStatus(newStatus);
    };

    return (
        <Fragment>
            <div className="row mb-5">
                <div className={inactiveStatus === null? "col text-success" : "col text-danger"}>
                        {inactiveStatus === null? "активная заявка" : "не активная заявка"}
                </div>
                <div className="col">
                    <button
                        className={inactiveStatus === null? "btn btn-danger btn-sm" : "btn btn-success btn-sm"}
                        data-inactivestatus = {inactiveStatus === null? "active" : "inactive"}
                        type="button"
                        onClick={onSetActiveStatus}>
                            {inactiveStatus === null? "Сделать не активной" : "Активировать"}
                    </button>
                </div>
            </div>
        </Fragment>
    )

};

ElementActiveSection.propTypes = {
    inactiveStatus: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    onSetActiveStatus: PropTypes.func
};
