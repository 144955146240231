import React, {Fragment} from "react";
import PropTypes         from "prop-types";

export const WishesComments = props => (
    <Fragment>
        <h5>Комментарии: </h5>

        <div className="list-group list-group-flush">
            {
                props.comments.map((comment, index) => (
                        <li key={index} className="list-group-item">
                            <p>{comment}</p>
                        </li>
                    )
                )
            }
        </div>
    </Fragment>
);

WishesComments.propTypes = {
    strComments: PropTypes.string,
    comments: PropTypes.array
};
