import {diary} from "../../index";
import {Utils} from "../../classes/Utils";

export let StudentPaymentsHandler = function (selector) {
    this.$element = $(selector);
    this.$form = this.$element.find("form");
    this.EDIT = "edit";
    this.DELETE = "delete";
    this.$paymentsList = $("#paymentsList");
    this.$submitButton = this.$form.find("button[type=submit]");
    this.$cancelButton = $("#cancelButton");
    this.gID = this.$element.find("input[name='gID']").val();
    this.sID = this.$element.find("input[name='sID']").val();
    this.dID = this.$element.find("input[name='dID']").val();
    this.$sum = $("#sum");
    this.studentPaymentsModel = "student.payments";

    this.dateFormat = app.config.$DEFAULT_DATEPICKER_DATE_FORMAT;
    this.addPayment = this.addPayment.bind(this);
    this.refreshPage = this.refreshPage.bind(this);
    this.editPayment = this.editPayment.bind(this);
    this.deletePayment = this.deletePayment.bind(this);
    this.updatePayment = this.updatePayment.bind(this);

    this.$submitButton.on("click touchend", this.addPayment);
    this.$cancelButton.on("click touchend", diary.closePopup);
    $("[data-action-type=edit]").on("click touchend", this.editPayment);
    $("[data-action-type=delete]").on("click touchend", this.deletePayment);
};

StudentPaymentsHandler.prototype.addPayment = function (event) {
    if (!this.$form.valid()) {
        return false;
    }
    event.preventDefault();
    let sum = $("#sum").val(),
        date = $("#paymentDate").val(),
        paymentType = $("#paymentType").val(),
        data = {
            "select": {
                "gID": this.gID,
                "sID": this.sID,
                "dID": this.dID,
                "ptype": paymentType,
                "operate": "add",
                "deb": sum,
                "date": date,
                "isAjax": true,
            },
        };
    diary.doAjax(this.studentPaymentsModel, data, this.refreshPage);
};

StudentPaymentsHandler.prototype.editPayment = function (event) {
    let $clickedButton = $(event.currentTarget);
    this.enableEditMode($clickedButton);
};

StudentPaymentsHandler.prototype.deletePayment = function (event) {
    var message = "Вы действительно хотите удалить запись об оплате без возможности восстановления?";
    if (!diary.getConfirm(message)) {
        return false;
    }
    let $clickedButton = $(event.currentTarget),
        paymentId = $clickedButton.siblings("input[name='payment_id']").val(),
        select = {
            "select": {
                "gID": this.gID,
                "sID": this.sID,
                "dID": this.dID,
                "payment_id": paymentId,
                "operate": "delete",
                "isAjax": true,
            },
        };
    diary.doAjax(this.studentPaymentsModel, select, this.refreshPage);
};

StudentPaymentsHandler.prototype.updatePayment = function (e) {
    let thisTR = $(e.target).parent().parent(),
        sum = thisTR.find("input[name='payment_sum']").val(),
        date = thisTR.find("input[name='payment_date']").val(),
        paymentId = thisTR.find("input[name='payment_id']").val(),
        select = {
            "select": {
                "gID": this.gID,
                "sID": this.sID,
                "dID": this.dID,
                "payment_id": paymentId,
                "operate": "update",
                "sum": sum,
                "date": date,
                "isAjax": true,
            },
        };
    diary.doAjax(this.studentPaymentsModel, select, this.refreshPage);
};

StudentPaymentsHandler.prototype.enableEditMode = function ($clickedButton) {
    let $thisRow = $clickedButton.parents("tr"),
        $deleteButton = $clickedButton.next(),
        $dateInput = $thisRow.find("input[name='payment_date']"),
        $sumInput = $thisRow.find("input[name='payment_sum']"),
        $displayData = $thisRow.find(".display_data");

    $dateInput.datepicker({
        dateFormat: app.config.DEFAULT_DATEPICKER_DATE_FORMAT,
        defaultDate: "0",
        changeMonth: true,
        beforeShow: Utils.correctInputPosition,
    });
    $deleteButton.hide();
    $displayData.hide();
    $clickedButton
        .html("сохранить")
        .unbind()
        .on("click", {"tr": $thisRow}, this.updatePayment);
    $sumInput.show();
    $dateInput.show();
};

StudentPaymentsHandler.prototype.refreshPage = function (answer) {
    this.$paymentsList.html(answer);
    this.$sum.val("");
    this.bindEventsOnButtons();
    //если были изменения в платежах, нужно обновить страницу деталей группы
    this.$cancelButton.unbind();
    this.$cancelButton.on("click touchstart", function () {
        diary.closePopup();
        diary.refreshPage();
    });
    this.$closeButton.off();
    this.$closeButton.on("click touchstart", function () {
        diary.closePopup();
        diary.refreshPage();
    })
};