export class PaymentsFilter {

    constructor() {
    }

    static run() {

        let $searchArea = $(".searchable"),
            searchVal = this.value.toLowerCase();

        if (searchVal.length >= 2) {

            //покажем только строки с вхождением искомых строк
            $searchArea.each(function (index, element) {

                let _this = $(element),
                    content = _this.html().toLowerCase(),
                    found = (content.indexOf(searchVal) !== -1);
                if (found) {
                    _this.show();
                    _this.next().show();
                } else {
                    _this.hide();
                    _this.next().hide();
                }

            });

        } else {

            //покажем всё
            $searchArea.show();
            $searchArea.next().show();

        }

    }
}
