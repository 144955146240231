import "../Enums";
import {legacy}         from "../../legacy";
import {diary}          from "../../index";
import {GroupsPayments} from "./GroupsPayments";
import {Pages}          from "./Pages";
import ReactDOM     from "react-dom";
import {WishesForm} from "../components/WishesForm";
import React        from "react";


export class GroupsCalculate extends Pages {

    constructor(params) {
        super(params);
    }

    initPageHandlers() {
        super.initPageHandlers();
        $('#search').on("change keyup input click", this.search);
    }

    search() {

        let searchVal = this.value.toLowerCase(),
            $contentRows = $(".content_row"),
            $contentItems = $(".content_item");

        if (searchVal.length >= 2) {
            //покажем только строки групп с вхождением искомых строк
            $contentRows.each(function (index, element) {

                let _thisRow = $(element),
                    content = _thisRow.find($contentItems).html().toLowerCase();

                if (content.indexOf(searchVal) !== -1) {
                    _thisRow.show();
                } else {
                    _thisRow.hide();
                }

            });
        } else {
            $contentRows.show();
        }

    }

}
