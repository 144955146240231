import {PaymentsFilter} from "../helpers/PaymentsFilter";
import {diary} from "../../index";
import {StudentPaymentsHandler} from "../widgets/StudentPaymentsHandler";
import {Pages} from "./Pages";


export class GroupsPayments extends Pages{

    initPageHandlers() {
        super.initPageHandlers();

        $(document).on("change keyup input click", "#search-field", PaymentsFilter.run);

        $(document).on("change keyup input click", ".edit-student-payment", (event) => {
            let $clicked = $(event.target);
            event.preventDefault();
            diary.getDataInModal("student.payments", $clicked.attr("data-payments-detail"));
            diary.onLoadInModal = GroupsPayments.initInModalHandler;
        });

        $(document).on("change keyup input click", ".edit-group-credit", (event) => {
            let clicked = event.target,
                gID = clicked.dataset.groupid;
            event.preventDefault();
            diary.openUrlInModal(`/groups/${gID}/credit/`);
        });

    }

    static initInModalHandler() {
        let $paymentsHandler = new StudentPaymentsHandler();
        $paymentsHandler.init("#payments_block");
    }

}