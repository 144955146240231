import React, {Fragment, useEffect, useState} from "react";
import {Spinner}                              from "../dom/Spinner";
import {PersonSection}                        from "./PersonSection";
import {ElementsContext}                      from "../enums/ElementsContext";
import {FieldsetHeader}                       from "../dom/FieldsetHeader";
import {ElementActiveSection}                 from "../dom/ElementActiveSection";
import {Select}                               from "../dom/Select";
import {WishesComments}                       from "./WishesComments";
import {ErrorContainer}                       from "../dom/ErrorContainer";
import CreatableSelect                        from 'react-select/creatable';

const axios = require("axios").default;

export const WishesForm = props => {
    const [errors, setErrors] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [element, setElement] = useState(props.element || {});
    const [programs, setPrograms] = useState([]);
    const [adverts, setAdverts] = useState([]);

    useEffect(() => {
    });

    useEffect(() => {
        setValidation();
        loadData();
    }, []);

    const handleChange = (event) => {
        const {value, id} = event.target;
        setElement({...element, [id]: value});
    };

    const onProgramChange = (program) => {
        let newProgramData = {
            program_id: program.ID,
            program: program,
        };
        setElement({...element, ...newProgramData});
    };

    const cancelBtnClick = (event) => {
        event.preventDefault();
        window.location.href = props.backUrl;
    };

    const setActiveStatus = (status) => {
        setElement({...element, inactive: status});
    };

    const onPersonChange = (person) => {
        setElement({...element, person_id: person.ID});
    };

    const setOptionsList = (source) => {
        return source.map(({ID, name}) => {
            return {"ID": ID, "name": name};
        });
    };

    const loadData = () => {
        setLoading(true);
        // получим программы, источники информации и данные по элементу
        const programRequest = () => {
            return axios.get('/api/programs/');
        };
        const advertsRequest = () => {
            return axios.get('/api/advers/');
        };
        const elementRequest = () => {
            if (props.elementId) {
                return axios.get(`/api/wishes/${props.elementId}/`);
            }
        };
        axios.all([programRequest(), advertsRequest(), elementRequest()])
             .then(axios.spread((programRequest, advertsRequest, elementRequest) => {
                 setPrograms(setOptionsList(programRequest.data));
                 setAdverts(setOptionsList(advertsRequest.data));
                 if (props.elementId) {
                     setElement(elementRequest.data);
                 }
                 setLoading(false);
                 setErrors([]);
             }))
             .catch((error) => {
                 setLoading(false);
                 setErrors(errors => [...errors, "Не удалось обработать запрос!"]);
             });
    };

    const setValidation = () => {
        $("#wishes_edit_form").validate(
            {
                rules: {
                    "person[ID]": {
                        required: true,
                    },
                    "element[adver_id]": {
                        required: true,
                    },
                    "element[program_id]": {
                        required: true,
                    },
                },
            },
        );
    };


    if (isLoading) {
        return (<Spinner/>);
    }

    return (
        <Fragment>
            <PersonSection
                context={ElementsContext.WISHES}
                onPersonChange={onPersonChange}
                personId={element.person_id}/>

            <form id="wishes_edit_form" action={props.formAction} method={"POST"}>
                <input name="backUrl"
                       type="hidden"
                       value={props.backUrl || ""}/>
                <input name="element[ID]"
                       type="hidden"
                       value={props.elementId || ""}/>
                <input name="element[inactive]"
                       type="hidden"
                       value={element.inactive || ""}/>
                <input name="element[person_id]"
                       type="hidden"
                       value={element.person_id || ""}/>
                <input name="time_insert"
                       type="hidden"
                       value={element.time_insert || ""}/>

                {errors.length > 0 ? <ErrorContainer arErrors={errors}/> : ""}

                <fieldset className="border-0">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <FieldsetHeader
                                text={"Заявка на обучение"}/>
                        </div>
                        <div className="col-12 col-md text-end">
                            <ElementActiveSection
                                onSetActiveStatus={setActiveStatus}
                                inactiveStatus={element.inactive}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-12 col-md-6">
                            <label className="display_block" htmlFor="program_id">Желаемая программа обучения</label>
                            <CreatableSelect
                                onChange={onProgramChange}
                                id={"program_id"}
                                name={"element[program_id]"}
                                placeholder="Желаемая программа обучения"
                                value={element.program || {ID: "", name: "Выбрать программу обучения"}}
                                options={programs}
                                getOptionLabel={option => option.name}
                                getOptionValue={option => option.ID}
                            />
                        </div>
                        <div className="form-group col-12 col-md-6">
                            <Select
                                onChange={handleChange}
                                isRequired={true}
                                id="adver_id"
                                name={"element[adver_id]"}
                                label={"Источник информации о центре"}
                                defaultOption={"Выбрать источник информации"}
                                options={adverts}
                                selected={element.adver_id || ""}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-12 col-md-6">
                            <input
                                id="current_comment"
                                name="element[current_comment]"
                                type="hidden"
                                value={element.current_comment || ""}/>

                            <WishesComments comments={element.array_of_comments || []}/>

                        </div>
                        <div className="form-group col-12 col-md-6">
                            <label className="display_block" htmlFor="comment">Добавить свои комментарии</label>
                            <textarea
                                onChange={handleChange}
                                className="form-control"
                                id="comment"
                                name="element[comment]"
                                value={element.comment || ""}/>
                            <small className="form-text text-muted">
                                Укажите в комментариях всю значимую информацию по контакту с желающим.
                                Предыдущие
                                комментарии см. в другом поле. Пользователь, дата и время будут записаны
                                автоматически.
                            </small>
                        </div>
                    </div>

                </fieldset>

                <div className="btn_container">
                    <button className="btn btn-primary"
                            type="submit"
                            name="submit"
                            value="Y">
                        Сохранить
                    </button>
                    <button
                        className="btn btn-secondary btn-cancel"
                        onClick={cancelBtnClick}>
                        отменить
                    </button>
                </div>

            </form>
        </Fragment>
    );
};
